import { lazy, useEffect } from "react";
import { createRoot } from "react-dom/client";

const LandingEntry = lazy(() => import("./entries/landing-entry"));
const AppEntry = lazy(() => import("./entries/app-entry"));

const isLandingUrl = (url: string) => {
  if (import.meta.env.VITE_BINDING_ENV === "production") {
    return url.startsWith("https://binding.at");
  }

  return url.startsWith("http://localhost:5173/landing");
};

const RootDecision = () => {
  useEffect(() => {
    const handleResize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isLandingUrl(window.location.href)) {
    return <LandingEntry />;
  }

  return <AppEntry />;
};

const rootEl = document.getElementById("root");
createRoot(rootEl).render(<RootDecision />);
